@charset "utf-8";

// ============================================================
// radio style
// ============================================================
// （例）
// <label>
//	<input name="radioname" class="radioInput" value="1" type="radio">
//	<span class="radioInput-style">ラベル名</span>
// </label>
// <label>
//	<input name="radioname" class="radioInput" value="1" type="radio">
//	<span class="radioInput-style">ラベル名</span>
// </label>

.radioInput {
	display: none;

	&:checked + .radioInput-style{
		color: $md-cyan-600;
	}

	&:checked + .radioInput-style {
		&:after{
			content: "";
			width: 10px;
			height: 10px;
			display: block;
			background: $md-cyan-600;
			border-radius: 50%;
			position: absolute;
			top: 5px;
			left: 3px;
			cursor: pointer;
		}
	}
}

.radioInput-style {
	margin-right: 20px;
	padding-left: 22px;
	position: relative;
	white-space: nowrap;

	&:before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background: $md-blue-grey-50;
		box-shadow: 0 0 0 2px rgba($md-white, 1) inset;
		border: 1px solid $md-blue-grey-200;
		border-radius: 50%;
		box-sizing: border-box;
		position: absolute;
		top: 2px;
		left: 0;
		cursor: pointer;
	}
}
