@charset "utf-8";

// ----------------------------------------------------------------------
// MediaQuery
// ----------------------------------------------------------------------

@mixin desk {
	// PC表示
	@media only screen and (min-width: $width-pc + $width-scrollbar) {
		@content;
	}
}

@mixin tablet {
	// タブレット表示
	@media only screen and (min-width: $width-smartphone1 + $width-scrollbar) and (max-width: $width-pc + $width-scrollbar - 1) {
		@content;
	}
}

@mixin tablet1 {
	// タブレット表示 1段階目(大)
	@media only screen and (min-width: $width-tablet2 + $width-scrollbar) and (max-width: $width-tablet1 + $width-scrollbar - 1) {
		@content;
	}
}

@mixin tablet2 {
	// タブレット表示 2段階目(小)
	@media only screen and (min-width: $width-smartphone1 + $width-scrollbar) and (max-width: $width-tablet2 + $width-scrollbar - 1) {
		@content;
	}
}

@mixin sp {
	// スマートフォン表示 1段階目(大)
	@media only screen and (max-width: $width-smartphone1 + $width-scrollbar - 1) {
		@content;
	}
}

@mixin sp1 {
	// スマートフォン表示 2段階目(小)
	@media only screen and (max-width: $width-smartphone2 + $width-scrollbar - 1) {
		@content;
	}
}


