@charset "utf-8";

// ============================================================
// loading style
// ============================================================

.loading {
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background:$md-white url(../images/common/loader.gif) no-repeat center;
	z-index:99999;
} //loading


