@charset "utf-8";

/*----------------------------------------
	menu
----------------------------------------*/
.menuTable {
	margin-bottom:30px;
	width:100%;
	box-sizing: border-box;
	
	@include tablet2 {
		margin-bottom: 0;
	} //tablet2
	
	@include sp {
		margin-bottom: 0;
	} //sp
	
	tr {
		@include tablet2 {
			border-top:1px solid $md-grey-300;
			border-bottom:1px solid $md-grey-300;
		} //tablet2
		
		@include sp {
			border-top:1px solid $md-grey-300;
			border-bottom:1px solid $md-grey-300;
		} //sp
	}
	
	th, td {
		padding:13px 13px 13px 0;
		box-sizing: border-box;
		line-height:140%;
		vertical-align:top;
		font-weight:400;
		
		@include sp {
			padding:10px 15px 10px 0;
			@include fs(13);
		} //sp
	} //th
	
	th {
		width:75%;
	}
	
	td {
		padding-right:0;
		width:25%;
		white-space:nowrap;
		text-align:right;
		
		@include sp {
			width:auto;
		} //sp
	} //td
	
	tbody {
		&:nth-of-type(n+2) {
			margin-top:-1px;
		}
	}
	
	&.rowBox__3elem, &.rowBox__2elem {
		.boxInner {
			margin: -1px 0 0;
		}
		
		th {
			width: 100%;
		}
	}

	&.rowBox__3elem--reverse, &.rowBox__2elem--reverse {
		.boxInner {
			margin: -1px 0 0;
		}
		
		th {
			width: 100%;
		}
	}
} //menuTable




