@charset "utf-8";

/*----------------------------------------
	shop
----------------------------------------*/
.shopInfo {
	margin:0;
	
	dt {
		margin:0;
	}
	
	dd {
		margin:0 0 15px;
	}
} //shopInfo


#map {
	width:100%;
	height:480px;
	
	@include tablet2 {
		height:400px !important;
	} //tablet2
	
	@include sp {
		height:300px !important;
	} //sp
	
	.gm-style-iw {
		color:$md-grey-900;
	}
} //map




