@charset "utf-8";

/*----------------------------------------
	footer
----------------------------------------*/

//contact
.fContact {
	padding: 0 15px;
	width: 100%;
	height: 440px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url(../img/common/pho_bg_contact.jpg) no-repeat center;
	background-size: cover;
	box-sizing: border-box;
	position: relative;
	color: $md-white;

	@include tablet2 {
		padding: 60px 15px;
		height: auto;
	} //tablet2

	@include sp {
		padding: 40px 15px;
		height: auto;
	} //sp

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		background: rgba($md-black, .7);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}

	& * {
		position: relative;
		z-index: 1;
	}
}

.fContact__hl {
	margin: 0;
	@include fs(30);
	font-weight: 500;

	@include sp {
		@include fs(24);
	} //sp
}

.fContact__read {
	margin: 15px 0 25px;
}

.fContact__tel {
	margin: 0;
	line-height: 120%;
	@include fs(54);
	letter-spacing: .03em;
	font-weight: 500;

	@include tablet1 {
		@include fs(50);
	} //tablet1

	@include tablet2 {
		@include fs(48);
	} //tablet2

	@include sp {
		@include fs(42);
	} //sp

	a {
		text-decoration: none;
		color: $md-white;
	}
}

.fContact__time {
	margin: 0;
}

.fContact__btn {
	margin: 30px auto 0;
	width: 400px;

	@include sp {
		width: 280px;
	} //sp

	.btn {
		background: rgba($md-white, .1);
		border: 1px solid $md-white;
		@include fs(20);
		color: $md-white !important;

		@include sp {
			@include fs(18);
		} //sp

		&:hover {
			background: rgba($md-white, 1);
			color: $textColor !important;

			.fa {
				color: $textColor;
				right: -5px;
			}
		}

		.fa {
			margin-left: 10px;
			color: $md-white;
			@include fs(15);
			position: relative;
			top: 1px;
			right: 0;
		}
	}
}



//footer
footer {
	padding: 56px 0 50px;
	width: 100%;
	background: $md-black;
	box-sizing: border-box;
	position: relative;

	@include tablet2 {
		padding: 30px 0 20px;
	} //tablet2

	@include sp {
		padding: 20px 0;
	} //sp
	
	&:before {
		content: '';
		width: 100%;
		height: 6px;
		display: block;
		background: rgba($md-white, .25);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.container {
		display: flex;

		@include tablet2 {
			flex-direction: column;
		} //tablet2

		@include sp {
			flex-direction: column;
		} //sp
	}
} //footer


.fLeft {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex-grow: 1;

	@include tablet2 {
		width: auto;
		text-align: center;
	} //tablet2

	@include sp {
		width: auto;
		text-align: center;
	} //sp
} //fLeft


.shopName {
	margin: 5px 0;
	color: $md-white;
	@include fs(17);
	
	@include tablet2 {
		margin: 20px auto 0;
	} //tablet2
	
	@include sp {
		margin: 20px auto 0;
	} //sp
}


.fAddress {
	margin:0;
	padding:0;
	
	@include tablet2 {
		margin: 10px auto 0;
		text-align:left;
	} //tablet2
	
	@include sp {
		margin: 10px auto 0;
		text-align:left;
	} //sp
	
	dt {
		width:68px;
		float:left;
		clear:left;
		@include fs(14);
		color:$md-white;
		position: relative;
		
		@include sp {
			width:58px;
			@include fs(12);
		} //sp

		&:before {
			content: '：';
			position: absolute;
			top: 0;
			right: 0;
		}
	} //dt
	
	dd {
		margin:0;
		padding-left:68px;
		@include fs(14);
		color:$md-white;
		
		@include sp {
			padding-left:58px;
			@include fs(12);
		} //sp

		span {
			color:$md-white;
		}

		a {
			color: $md-white;
		}
	} //dd
} //fAddress


.fRight {
	margin-left: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include tablet2 {
		width: 100%;
	} //tablet2

	@include sp {
		width: 100%;
	} //sp
} //fRight

.snsList {
	margin: 10px 0 0;
	padding: 0;
	display: flex;
	
	@include tablet2 {
		margin: 30px 0 0;
	} //tablet2
	
	@include sp {
		margin: 30px 0 0;
	} //sp
	
	li {
		margin: 0 10px;
		list-style: none;
		
		a {
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: rgba($md-white, 1);
			color: $md-black;
			@include fs(22);
			text-decoration: none;
			
			&:hover {
				background: rgba($md-white, .6);
			}
			
			i {
				color: $md-black;
				@include fs(22);
			}
		}
	}
}

.copy {
	line-height: 120%;
	color: $md-white;
	text-align: center;

	@include tablet2 {
		margin-top: 10px;
		padding-top: 15px;
		width: 100%;
		border-top: 1px solid rgba(255,255,255,.1);
	} //tablet2

	@include sp {
		margin-top: 10px;
		padding-top: 15px;
		width: 100%;
		border-top: 1px solid rgba(255,255,255,.1);
	} //sp

	small {
		@include fs(12);
		color: $md-white;
	}
} //copy








