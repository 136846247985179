@charset "utf-8";

/*----------------------------------------
	header
----------------------------------------*/

// headerグローバルナビカラー設定
$gNavColor: $md-black;

header {
	width: 100%;
	background: $md-white;
	box-sizing: border-box;
	position: relative;
	z-index: 2;

	@include tablet1 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
	} //tablet1

	@include tablet2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
	} //tablet2

	@include sp {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
	} //sp
}


.hTop {
	margin: 0 auto;
	width: 100%;
	max-width: $baseWidth;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	background: $md-white;
	position: relative;
	z-index: 3;

	@include tablet1 {
		margin: 0 auto;
		box-shadow: 0 0 10px 0 rgba($md-black, .1);
	} //tablet1

	@include tablet2 {
		margin: 0 auto;
		box-shadow: 0 0 10px 0 rgba($md-black, .1);
	} //tablet2

	@include sp {
		margin: 0 auto;
		box-shadow: 0 0 10px 0 rgba($md-black, .1);
	} //sp
}


.hTop__hl {
	margin: 25px 30px;
	display: flex;
	align-items: center;

	@include tablet1 {
		margin: 10px 5px 10px 10px;
	} //tablet1

	@include tablet2 {
		margin: 10px 5px 10px 10px;
	} //tablet2

	@include sp {
		margin: 10px 5px 10px 10px;
	} //sp

	a {
		display: block;
		@include fs(26);
		text-decoration: none;

		&:hover {
			opacity: .8;
		}
	} //a

	img {
		max-height: 76px;
		vertical-align: bottom;

		@include tablet1 {
			max-height: 66px;
		} //tablet1

		@include tablet2 {
			max-height: 56px;
		} //tablet2

		@include sp {
			max-height: 44px;
		} //sp
	}
}


.hTop__contents {
	margin: 0 30px 20px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include tablet1 {
		margin: 0 0 0 auto;
	} //tablet1

	@include tablet2 {
		margin: 0 0 0 auto;
	} //tablet2

	@include sp {
		margin: 0 0 0 auto;
	} //sp
}

.hContact {
	margin: 0;
	display: flex;
	flex-direction: column;

	@include tablet {
		margin-right: 20px;
	} //tablet

	@include tablet1 {
		margin: 0 !important;
		flex-direction: row;
		flex-wrap: wrap;
	} //tablet1

	@include tablet2 {
		margin: 0 !important;
		flex-direction: row;
		flex-wrap: wrap;
	} //tablet2

	@include sp {
		margin: 0;
		flex-direction: row;
		flex-wrap: wrap;
	} //sp
}

.hContact__item {
	margin: 0;
	line-height: 130%;

	& * {
		line-height: 130%;
	}

	&--btn {
		margin-bottom: auto;

		@include tablet1 {
			margin: 0 5px;
		} //tablet1

		@include tablet2 {
			margin: 0 5px;
		} //tablet2

		@include sp {
			margin: 0 0 0 5px;
		} //sp
	}

	&--tel {
		margin-top: 8px;

		@include tablet1 {
			margin: 0 5px;
		} //tablet1

		@include tablet2 {
			margin: 0 5px;
		} //tablet2

		@include sp {
			margin: 0 0 0 5px;
		} //sp
	}

	&--hour {
		margin-top: 5px;
		@include fs(14);

		@include tablet1 {
			display: none;
		} //tablet1

		@include tablet2 {
			display: none;
		} //tablet2

		@include sp {
			display: none;
		} //sp
	}
}

.hContact__btn {
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $cBase2;
	border: 1px solid $cBase2;
	border-top: none;
	box-sizing: border-box;
	@include border-bottom-radius(3px);
	@include fs(14);
	font-weight: 500;
	color: $md-white;
	text-decoration: none;

	@include tablet1 {
		padding: 0;
		background: none !important;
		border: none !important;
	} //tablet1

	@include tablet2 {
		padding: 0;
		background: none !important;
		border: none !important;
	} //tablet2

	@include sp {
		padding: 0;
		background: none !important;
		border: none !important;
	} //sp

	&:hover {
		background: $md-white;
		color: $cBase2;

		& * {
			color: $cBase2;
		}

		.fa {
			color: $cBase2;

			@include tablet1 {
				color: $md-black;
			} //tablet1

			@include tablet2 {
				color: $md-black;
			} //tablet2

			@include sp {
				color: $md-black;
			} //sp
		}
	}

	.fa {
		margin-right: 5px;
		color: $md-white;

		@include tablet1 {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			color: $md-black;
			@include fs(18);
		} //tablet1

		@include tablet2 {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			color: $md-black;
			@include fs(18);
		} //tablet2

		@include sp {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			color: $md-black;
			@include fs(18);
		} //sp
	}

	&--text {
		color: $md-white;

		@include tablet1 {
			display: none;
		} //tablet1

		@include tablet2 {
			display: none;
		} //tablet2

		@include sp {
			display: none;
		} //sp
	}
}

.hContact__tel {
	line-height: 120%;
	display: inline-flex;
	align-items: center;
	@include fs(30);
	pointer-events: none;
	text-decoration: none;
	font-weight: 700;
	color: $md-black !important;

	@include tablet {
		pointer-events: auto;
	} //tablet

	@include sp {
		pointer-events: auto;
	} //sp

	.fa {
		margin-right: 5px;
		@include fs(22);
		color: $md-black;
		position: relative;
		top: 2px;

		@include tablet1 {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			@include fs(18);
			position: static;
		} //tablet1

		@include tablet2 {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			@include fs(18);
			position: static;
		} //tablet2

		@include sp {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $md-black;
			border-radius: 50%;
			box-sizing: border-box;
			@include fs(18);
			position: static;
		} //sp
	}

	&--num {
		@include tablet1 {
			display: none;
		} //tablet1

		@include tablet2 {
			display: none;
		} //tablet2

		@include sp {
			display: none;
		} //sp
	}
}


.spMenu__btn {
	margin: 0 20px;
	width: 40px;
	height: 40px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $gNavColor;
	display: none;
	cursor: pointer;

	@include tablet1 {
		display: flex;
	} //tablet1

	@include tablet2 {
		margin: 0 15px;
		display: flex;
	} //tablet2

	@include sp {
		margin: 0 15px 0 10px;
		display: flex;
	} //sp

	&.active {
		.spMenu__btn--line {

			&:nth-child(1) {
				transform: rotate(-45deg);
				top: 9px;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(45deg);
				top: -9px;
			}
		}
	}
}

.spMenu__btn--line {
	margin: 3px 0;
	width: 26px;
	height: 3px;
	display: block;
	background: $md-white;
	transition: all 0.3s ease;
	position: relative;
	top: 0;
}


.gNav {
	width: 100%;
	height: 64px;
	background: $gNavColor;
	position: relative;
	border-top: 1px solid rgba($md-white, .15);
	box-sizing: border-box;
	position: relative;
	z-index: 2;

	@include tablet1 {
		width: 100%;
		height: 100vh;
		display: none;
		background: rgba($md-black, .8) !important;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	} //tablet1

	@include tablet2 {
		width: 100%;
		height: 100vh;
		display: none;
		background: rgba($md-black, .8) !important;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	} //tablet2

	@include sp {
		width: 100%;
		height: 100vh;
		display: none;
		background: rgba($md-black, .8) !important;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	} //sp

	&:after {
		content: '';
		width: 100%;
		height: 6px;
		display: block;
		background: rgba($md-black, .2);
		position: absolute;
		bottom: -6px;
		left: 0;
		right: 0;
		z-index: 1;

		@include tablet1 {
			display: none;
		} //tablet1

		@include tablet2 {
			display: none;
		} //tablet2

		@include sp {
			display: none;
		} //sp
	}

	&.fixed {
		position: fixed;
		top: 0;
	}
}


.nav-menu__box {
	margin: 0 auto;
	max-width: $baseWidth;

	@include tablet1 {
		width: 260px;
		height: 100%;
		background: $gNavColor;
		display: flex;
		flex-direction: column;
		transition: all 0.5s ease;
		transform: translate3d(260px, 0, 0);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	} //tablet1

	@include tablet2 {
		width: 260px;
		height: 100%;
		background: $gNavColor;
		display: flex;
		flex-direction: column;
		transition: all 0.5s ease;
		transform: translate3d(260px, 0, 0);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	} //tablet2

	@include sp {
		width: 260px;
		height: 100%;
		background: $gNavColor;
		display: flex;
		flex-direction: column;
		transition: all 0.5s ease;
		transform: translate3d(260px, 0, 0);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	} //sp

	&.active {
		transform: translate3d(0, 0, 0);
	}
}


.nav-menu {
	margin:0 auto;
	padding:0;
	display: flex;

	@include tablet1 {
		padding-bottom: 220px;
		width: 100%;
		display: block;
	} //tablet1

	@include tablet2 {
		padding-bottom: 220px;
		width: 100%;
		display: block;
	} //tablet2

	@include sp {
		padding-bottom: 220px;
		width: 100%;
		display: block;
	} //sp
}


.nav-item {
	list-style: none;
	flex-grow: 1;
	box-sizing: border-box;
	border-right:1px solid rgba($md-white,.15);
	position: relative;

	@include tablet1 {
		width:100%;
		border-right: none;
	} //tablet1

	@include tablet2 {
		width:100%;
		border-right: none;
	} //tablet2

	@include sp {
		width:100%;
		border-right: none;
	} //sp

	&:first-child {
		border-left:1px solid rgba($md-white,.15);

		@include tablet1 {
			border-left: none;
		} //tablet1

		@include tablet2 {
			border-left: none;
		} //tablet2

		@include sp {
			border-left: none;
		} //sp
	}

	> a {
		width: auto;
		height: 63px;
		line-height: 120%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		@include fs(15);
		background: $gNavColor;
		color: $md-white !important;
		text-decoration: none;
		position: relative;
		overflow: hidden;

		@include tablet1 {
			padding: 0 20px;
			justify-content: flex-start;
			border-top: 1px solid rgba($md-white, .2);
			width: 100%;
			height: 48px;
		} //tablet1

		@include tablet2 {
			padding:  0 20px;
			justify-content: flex-start;
			border-top: 1px solid rgba($md-white, .2);
			width: 100%;
			height: 48px;
		} //tablet2

		@include sp {
			padding: 0 20px;
			justify-content: flex-start;
			border-top: 1px solid rgba($md-white, .2);
			width: 100%;
			height: 48px;
		} //sp

		&:after {
			content: '';
			width: 100%;
			height: 240%;
			display: block;
			background: rgba($md-white, .15);
			transform: skewY(-15deg);
			position: absolute;
			bottom: -290%;
			left: 0;
			transition: all 0.5s ease;
			
			@include tablet1 {
				display: none;
			} //tablet1

			@include tablet2 {
				display: none;
			} //tablet2

			@include sp {
				display: none;
			} //sp
		}

		&:hover {
			&:after {
				bottom: -60%;
			}
		}
	}

	&.current {
		> a {
			&:before {
				content: '';
				width: 98%;
				height: 1px;
				display: block;
				background: rgba($md-white, .4);
				position: absolute;
				bottom: 3px;
				left: 50%;
				transform: translate(-50%, 0);
				z-index: 1;

				@include tablet1 {
					display: none;
				} //tablet1

				@include tablet2 {
					display: none;
				} //tablet2

				@include sp {
					display: none;
				} //sp
			}
		}
	}
}


.nav-menu__sub {
	margin: 0;
	padding: 0;
	width: 100%;
	display: none;
	box-sizing: border-box;
	position: absolute;
	top: 63px;
	left: 0;
	z-index: 2;

	@include tablet {
		top: 49px;
	} //tablet

	@include tablet1 {
		display: block !important;
		position: static;
		border: none;
	} //tablet1

	@include tablet2 {
		display: block !important;
		position: static;
		border: none;
	} //tablet2

	@include sp {
		display: block !important;
		position: static;
		border: none;
	} //sp

	> .menu {
		margin: 0;
		padding: 0;
	}

	.menu-content {
		padding: 0;
		background: $gNavColor;
		list-style: none;

		> a {
			width: 100%;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			background: $gNavColor;
			border-top: 1px solid rgba($md-white, .4);
			@include fs(15);
			color: $md-white;
			text-decoration: none;
			position: relative;
			overflow: hidden;

			@include tablet {
				height: 48px;
				background: rgba($md-white, .1);
			} //tablet

			@include tablet1 {
				padding: 0 20px 0 35px;
				height: 48px;
				justify-content: flex-start;
				background: rgba($md-white, .1);
				border-top: 1px solid rgba($md-white, .2);
			} //tablet1

			@include tablet2 {
				padding: 0 20px 0 35px;
				height: 48px;
				justify-content: flex-start;
				background: rgba($md-white, .1);
				border-top: 1px solid rgba($md-white, .2);
			} //tablet2

			@include sp {
				padding: 0 20px 0 35px;
				height: 48px;
				justify-content: flex-start;
				background: rgba($md-white, .1);
				border-top: 1px solid rgba($md-white, .2);
			} //sp

			&:after {
				content: '';
				width: 100%;
				height: 240%;
				display: block;
				background: rgba($md-white, .15);
				transform: skewY(-15deg);
				position: absolute;
				bottom: -300%;
				left: 0;
				transition: all 0.5s ease;

				@include tablet1 {
					display: none;
				} //tablet1

				@include tablet2 {
					display: none;
				} //tablet2

				@include sp {
					display: none;
				} //sp
			}

			&:hover {
				&:after {
					bottom: -70%;
				}
			}
		}
	}
}



