@charset "utf-8";

// ============================================================
// font-size style
// ============================================================

@for $i from 10 through 32 {
	.fs#{$i} {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
	}
	.fs#{$i}-100 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 100;
	}
	.fs#{$i}-300 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 300;
	}
	.fs#{$i}-350 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 350;
	}
	.fs#{$i}-400 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 400;
	}
	.fs#{$i}-500 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 500;
	}
	.fs#{$i}-700 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 700;
	}
	.fs#{$i}-900 {
		font-size: #{$i}px;
		font-size: ($i / 16) * 1rem;
		font-weight: 900;
	}
}
