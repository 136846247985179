@charset "utf-8";

// ============================================================
// embed style
// ============================================================

//Youtube
.video {
	margin: 40px auto;
	padding-top: 56.25%;
	width: 100%;
	position: relative;
	
	iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}
}

// GoogleMap Ifram
.googleMap {
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	position: relative;
	overflow: hidden;

	iframe, object, embed {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}