@charset "utf-8";

// ============================================================
// pagetop style
// ============================================================

.pagetop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
	
	@include sp {
		bottom: 40px;
		right: 10px;
	} //sp
	
	a {
		width: 45px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $md-light-green-800;
		border-radius: 3px;
		box-sizing: border-box;
		text-decoration: none;
		
		@include sp {
			width: 40px;
			height: 40px;
		} //sp
		
		&:hover {
			opacity: .5;
		}
		
		i {
			display: block;
			@include fs(22);
			color: $md-white;
		}
	} //a
} //pagetop




