@charset "utf-8";

// ============================================================
// 共通部分
// ============================================================

/*----------------------------------------
	body
----------------------------------------*/
body {
	background: $md-white;
	font-family: $fontNotoSans;
	@include fs(16);
	color: $textColor;
	text-align: center;
	font-weight: 400;
	@include imgmax;
	
	@include sp {
		@include fs(14);
	} //sp
	
	& * {
		line-height: $lineHeight;
	}

	&.fixed {
		position: fixed;
		width: 100%;
		z-index: -1;
	}
}

a {
	color: $linkColor;
	outline: none;
	transition: all 0.5s ease;
	
	& * {
		transition: all 0.5s ease;
	}
	
	&:hover {
		color: $linkColor_hover;
		
		//iOS 8.4.1以降のopacity対策
		@include tablet {
			opacity: 1 !important;
			
			img {
				opacity: 1 !important;
			}
		} //tablet
		
		//iOS 8.4.1以降のopacity対策
		@include sp {
			opacity: 1 !important;
			
			img {
				opacity: 1 !important;
			}
		} //sp
	}
	
	&:active {
		color: $linkColor_active;
	}
} //a

h1, h2, h3, h4, h5, h6, b, strong {
	font-weight: 500;
}

@include clearfixBase;

main {
	position: relative;
	z-index: 0;
}


/*----------------------------------------
	container
----------------------------------------*/
.container {
	margin: 0 auto;
	width: $baseWidth;
	text-align: left;
	position: relative;
	@extend %clear;
	
	@include tablet {
		width: auto;
		margin: 0 20px;
	} //tablet
	
	@include sp {
		width: auto;
		margin: 0 15px;
	} //sp
} //container



/*----------------------------------------
	contents
----------------------------------------*/
.contents {
	padding-bottom: 100px;
	
	@include sp {
		padding-bottom: 50px;
	} //sp
} //contents

.contentsTitle {
	margin-bottom: 80px;
	width: 100%;
	background: url(../img/common/bg_arches.jpg);
	
	@include tablet2 {
		margin-bottom: 60px;
	} //tablet2
	
	@include sp {
		margin-bottom: 30px;
	} //sp
}

.contentsTitle__hl {
	margin: 0;
	width: 100%;
	height: 160px;
	line-height: 130%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #EEE;
	vertical-align: middle;
	text-align: center;
	@include fs(34);
	font-weight: normal;

	@include tablet2 {
		height: 130px;
		@include fs(26);
	} //tablet2

	@include sp {
		height: 100px;
		@include fs(22);
	} //sp
}


.section {
	margin: 100px 0;
	@extend %clear;
	
	@include tablet {
		margin: 60px 0;
	} //tablet
	
	@include sp {
		margin: 40px 0;
	} //sp
} //section


.section__hl {
	margin: 0 0 40px;
	padding-bottom: 25px;
	line-height: 120%;
	@include fs(30);
	position: relative;
	text-align: center;
	font-weight: normal;

	@include tablet2 {
		margin: 0 0 30px;
		padding-bottom: 15px;
		@include fs(24);
	} //tablet2

	@include sp {
		margin: 0 0 30px;
		padding-bottom: 15px;
		@include fs(20);
	} //sp
	
	& * {
		line-height: 120%;
	}

	&:before {
		margin-left: -30px;
		content: '';
		width: 60px;
		height: 1px;
		background: $md-light-green-800;
		position: absolute;
		bottom: 0;
		left: 50%;

		@include sp {
			margin-left: -15px;
			width: 30px;
		} //sp
	}
	
	&--left {
		text-align: left;
		font-weight: normal;

		@include tablet2 {
			margin: 0 0 30px;
			padding-bottom: 15px;
			@include fs(24);
		} //tablet2

		@include sp {
			margin: 0 0 30px;
			padding-bottom: 15px;
			@include fs(20);
		} //sp

		&:before {
			margin-left: 0;
			left: 0;

			@include sp {
				margin-left: 0;
			} //sp
		}
	}

	&--right {
		text-align: right;
		font-weight: normal;

		@include tablet2 {
			margin: 0 0 30px;
			padding-bottom: 15px;
			@include fs(24);
		} //tablet2

		@include sp {
			margin: 0 0 30px;
			padding-bottom: 15px;
			@include fs(20);
		} //sp

		&:before {
			margin-left: 0;
			left: inherit;
			right: 0;

			@include sp {
				margin-left: 0;
			} //sp
		}
	}
}

.telLink {
	a {
		text-decoration: none;
	}
}