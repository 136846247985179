@charset "utf-8";

/*----------------------------------------
	index
----------------------------------------*/
.index {
} //index


/* main visual */
.mainVisual {
	margin-bottom: 60px;
}

.mainVisual__inner {
	width: 100%;
	position: relative;
	
	&:after {
		content: '';
		width: 100%;
		height: 6px;
		background: rgba($md-black,.15);
		position: absolute;
		bottom: 6px;
		left: 0;
		right: 0;
	}
	
	.vegas-wrapper {
		width:100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
} //mainVisual

@include keyframes(animationSample){
	0% {
		opacity: 0;
		top: -15px;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}

.mainVisual__hl {
	margin: 0 15px;
	padding-bottom: 40px;
	line-height: 130%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	text-shadow: 1px 1px 1px rgba($md-black, .8);
	@include animation(animationSample 1.5s ease-out 0.5s);
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	color: $md-white;
	@include fs(42);
	letter-spacing: -.05em;
	font-weight: 700;
	z-index: 1;
	pointer-events: none;

	@include tablet1 {
		@include fs(36);
	} //tablet1

	@include tablet2 {
		@include fs(28);
	} //tablet2

	@include sp {
		@include fs(21);
	} //sp

	* {
		line-height: 130%;
	}
}


.mainVisualList {
	//display:none;
}


.mainVisualList__item {
	height:560px !important;
	background-position: center;
	background-size: cover;

	@include tablet1 {
		height:420px !important;
	} //tablet1

	@include tablet2 {
		height:340px !important;
	} //tablet2

	@include sp {
		height:260px !important;
	} //sp
	
	a {
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		
		&:hover {
			&:before {
				background: rgba($md-white, .2);
			}
		}
		
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			background: rgba($md-white, 0);
			transition: all 0.5s ease;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}
	}
	
	img {
		display: none;
		vertical-align: bottom;
	}
}


/* index contents */
.indexContents {
	margin:0 0 160px;
	
	@include tablet1 {
		margin:0 0 50px;
	} //tablet1
	
	@include tablet2 {
		margin:0 0 50px;
	} //tablet2
	
	@include sp {
		margin:0 0 20px;
	} //sp
	
	.container {
		width: auto;
		max-width: 1000px;
	}
} //indexContents


// お知らせ・新着情報
.topNews {
	margin: -120px 0 80px;
	padding: 20px 0 20px 20px;
	display: flex;
	justify-content: space-between;
	background: $md-white;
	box-shadow: 0 0 10px 0 rgba($md-black, .2);

	@include tablet1 {
		margin: -100px 0 60px;
	} //tablet1

	@include tablet2 {
		margin: -100px 0 60px;
		padding: 15px;
		flex-direction: column;
	} //tablet2

	@include sp {
		margin: -90px 0 40px;
		padding: 10px;
		flex-direction: column;
	} //sp
} //secTopNews

.topNews__box {
	width: 18%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: $md-black;

	@include tablet2 {
		padding: 10px 0 20px;
		width: 100%;
	} //tablet2

	@include sp {
		padding: 8px 0 16px;
		width: 100%;
	} //sp

	.topNews__hl {
		margin: 0 auto;
		width: 100%;
		@include fs(20);
		color: $md-white;
		position: relative;
		text-align: center;

		@include sp {
			@include fs(17);
		} //sp
		
		&:after {
			content: '';
			width: 50px;
			height: 1px;
			background: $md-white;
			display: block;
			position: absolute;
			bottom: -5px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		span {
			margin-top: 5px;
			display: block;
			@include fs(11);
			color: $md-black;
			font-weight: 400;
			line-height: 100%;

			@include sp {
				@include fs(10);
			} //sp
		}
	} //h2
}

.topNewsList {
	width: 82%;

	@include tablet2 {
		margin-top: 10px;
		width: 100%;
	} //tablet2

	@include sp {
		margin-top: 10px;
		width: 100%;
	} //sp
} //articleTopNews

.topNewsList__item {
	display: flex;
	justify-content: space-between;
	
	&:last-child {
		@include tablet2 {
			margin-bottom: 0;
		} //tablet2
		
		@include sp {
			margin-bottom: 0;
		} //sp
	}
}

.topNewsList__figure {
	width: 120px;
	height: auto;

	img {
		vertical-align: bottom;
	}
}

.topNewsList__detail {
	width: 100%;

	a {
		padding: 10px 50px 15px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		text-decoration: none;
		color: $md-black;
		position: relative;

		@include sp {
			padding: 10px 40px 15px 15px;
		} //sp

		&:after {
			content: '';
			background: $md-grey-50;
			display: block;
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			transition: all 0.3s ease;
		}

		&:hover {

			&:after {
				width: 100%;
			}

			.topNewsList__hl {
				color: $md-light-green-800;
			}

			.fas {
				right: 15px;
				color: $md-light-green-800;
			}
		}

		.fas {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translate(0, -50%);
			z-index: 1;

			@include sp {
				right: 10px;
			} //sp
		}
	}
}

.topNewsList__hl {
	margin: 5px 0 0;
	width: 100%;
	line-height: 130%;
	font-weight:400;
	@include fs(14);
	order: 2;
	position: relative;
	z-index: 1;

	@include tablet2 {
		margin: 0 0 5px !important;
		width: 100%;
	} //tablet2

	@include sp {
		margin: 0 0 5px !important;
		width: 100%;
	} //sp
}

.topNewsList__meta {
	margin-bottom: 3px;
	display: flex;
	order: 1;
	position: relative;
	z-index: 1;
}

.topNewsList__date {
	width: 90px;
	line-height: 20px;
	@include fs(14);
	color: $md-grey-400;
}

.topNewsList__category {
	margin: 0;
	padding: 0 8px;
	height: 21px;
	display: flex;
	align-items: center;
	border: 1px solid $md-grey-600;
	border-radius: 3px;
	box-sizing: border-box;
	@include fs(11);
	color: $textColor !important;
}

.topNewsList__noEntry {
	margin:0;
	padding:20px;
	background:$md-grey-200;
	@include fs(16);
	font-weight:400;
}


// トップコンテンツ
.indexContainer {
	margin: 0 auto;
	max-width: 1800px;
}

.boxInner__top {
	&--right {
		padding: 0 5% 0 3%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
	}
	
	&--left {
		padding: 0 3% 0 5%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
	}
}




