@charset "utf-8";

// ============================================================
// table style
// ============================================================

table {
	border-collapse:collapse;
	border-spacing:0;
}

caption, th{
	text-align:left;
}

.baseTable {
	width: 100%;
	background: $md-white;
	box-sizing: border-box;
	
	th, td {
		padding: 16px;
		line-height: 120%;
		border: 1px solid $md-grey-300;
		box-sizing: border-box;
		vertical-align: top;
		
		@include sp {
			padding: 12px 8px;
		} //sp
	}
	
	td {
		vertical-align: middle;
	}
	
	th {
		width: 22%;
		background: $md-grey-50;
		font-weight: 500;
		text-align: left;
	}
	
	
	&__sp {
		tr {
			@include tablet2 {
				display: block;
				width: 100%;
			} //tablet2
			
			@include sp {
				display: block;
				width: 100%;
			} //sp
		}
		
		th, td {
			@include tablet2 {
				margin-top: -1px;
				display: block;
				width: 100%;
			} //tablet2
			
			@include sp {
				margin-top: -1px;
				display: block;
				width: 100%;
			} //sp
		}
	}
} //baseTable


