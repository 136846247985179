@charset "utf-8";

// ============================================================
// 共通部分
// ============================================================

//col contents
.colBox {
	margin: 120px 0;

	@include tablet1 {
		margin: 90px 0;
	} //tablet1

	@include tablet2 {
		margin: 60px 0;
	} //tablet2

	@include sp {
		margin: 50px 0;
	} //sp

	p {
		line-height: 180%;
	}

	img {
		vertical-align: bottom;
	}

	&__bgColor {
		padding: 100px 0;

		@include tablet2 {
			padding: 70px 0;
		} //tablet2

		@include sp {
			padding: 40px 0;
		} //sp
	}
}

.contents__hl {
	margin:20px 0 30px;
	line-height:130%;
	@include fs(34);
	font-weight: 500;

	@include tablet1 {
		margin:5px 0 20px;
		@include fs(28);
	} //tablet1

	@include tablet2 {
		margin:30px 0 20px;
		@include fs(26);
	} //tablet2

	@include sp {
		margin:15px 0 20px;
		padding-right:10px;
		@include fs(24);
	} //sp

	&--center {
		text-align: center;
	}

	br {
		line-height:130%;
	}
}

.contents__readBox {
	margin-top: 40px;
	
	&--center {
		text-align: center;
	}
}


//flexbox contents 
.rowBox {
	&__2elem {
		margin: 40px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include sp {
			margin: 0;
			display: block;
		} //sp

		.boxInner {
			margin: 20px 0;
			width: calc(50% - 20px);
			
			@include tablet2 {
				width: calc(50% - 10px);
			} //tablet2
			
			@include sp {
				margin: 20px 0;
				width: 100%;
			} //sp
		}
	}
	
	&__2elem--reverse {
		margin: 40px 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		flex-wrap: wrap;

		@include sp {
			margin: 0;
			display: block;
		} //sp

		.boxInner {
			margin: 20px 0;
			width: calc(50% - 20px);

			@include tablet2 {
				width: calc(50% - 10px);
			} //tablet2

			@include sp {
				margin: 20px 0;
				width: 100%;
			} //sp
		}
	}
	
	&__3elem {
		margin: 40px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet2 {
			justify-content: space-around;
		} //tablet2

		@include sp {
			margin: 0;
			display: block;
		} //sp
		
		&:after {
			content: '';
			width: calc(33.333% - 30px);
			display: block;

			@include tablet2 {
				width: calc(50% - 20px);
			} //tablet2

			@include sp {
				display: none;
			} //sp
		}

		.boxInner {
			margin: 20px 0;
			width: calc(33.333% - 30px);

			@include tablet2 {
				margin: 20px 0;
				width: calc(50% - 20px);
			} //tablet2

			@include sp {
				margin: 20px 0;
				width: 100%;
			} //sp
		}
	}

	&__3elem--reverse {
		margin: 40px 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet2 {
			margin: 0;
			justify-content: space-around;
		} //tablet2

		@include sp {
			margin: 0;
			display: block;
		} //sp

		&:after {
			content: '';
			width: calc(33.333% - 30px);
			display: block;

			@include tablet2 {
				width: calc(50% - 20px);
			} //tablet2

			@include sp {
				display: none;
			} //sp
		}

		.boxInner {
			margin: 20px 0;
			width: calc(33.333% - 30px);

			@include tablet2 {
				margin: 20px 0;
				width: calc(50% - 20px);
			} //tablet2

			@include sp {
				margin: 20px 0;
				width: 100%;
			} //sp
		}
	}

	&__4elem {
		margin: 40px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet2 {
			margin: 0;
			justify-content: space-around;
		} //tablet2

		@include sp {
			margin: 0;
			display: block;
		} //sp

		&:before {
			content: '';
			width: calc(25% - 20px);
			display: block;
			order: 1;
			
			@include sp {
				display: none;
			} //sp
		}

		&:after {
			content: '';
			width: calc(25% - 20px);
			display: block;

			@include sp {
				display: none;
			} //sp
		}

		.boxInner {
			margin: 10px 0;
			width: calc(25% - 20px);

			@include tablet2 {
				width: calc(50% - 30px);
			} //tablet2

			@include sp {
				margin: 20px 0;
				width: 100%;
			} //sp
		}
	}

	&__4elem--reverse {
		margin: 40px 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet2 {
			margin: 0;
			justify-content: space-around;
		} //tablet2

		@include sp {
			margin: 0;
			display: block;
		} //sp

		&:before {
			content: '';
			width: calc(25% - 20px);
			display: block;
			order: 1;

			@include tablet2 {
				width: calc(50% - 30px);
			} //tablet2

			@include sp {
				display: none;
			} //sp
		}

		&:after {
			content: '';
			width: calc(25% - 20px);
			display: block;

			@include tablet2 {
				width: calc(50% - 30px);
			} //tablet2

			@include sp {
				display: none;
			} //sp
		}

		.boxInner {
			margin: 10px 0;
			width: calc(25% - 20px);

			@include tablet2 {
				width: calc(50% - 30px);
			} //tablet2

			@include sp {
				margin: 10px 0;
				width: 100%;
			} //sp
		}
	}
}

