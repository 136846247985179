@charset "utf-8";

// ============================================================
// comment style
// ============================================================

.blogComment {
	margin: 80px 0 0;
	padding: 15px;
	border: 1px solid $md-grey-300;
	border-radius: 3px;
	box-sizing: border-box;
	
	@include sp {
		margin: 40px 0 0;
		padding: 10px;
	} //sp
	
	.submit {
		margin-top: 0;
		padding: 30px 0;
		text-align: center;
		
		@include sp {
			padding: 15px 0 10px;
		} //sp
	}
}

.blogComment__hl {
	margin: 10px 0 20px;
	background: none;
	border: none;
	@include fs(18);
	font-weight: 700;
	text-align: left;
	color: $md-grey-900;

	@include sp {
		@include fs(16);
	} //sp

	&:after {
		display: none;
	}
}


.blogCommentList {
	margin: 10px 0;
	padding: 0 15px 40px;
	border-bottom: 1px solid $md-grey-300;
	
	@include sp {
		margin-bottom: 20px;
		padding: 0 10px 20px;
	} //sp
}

.comment {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px dotted $md-grey-300;
	
	&:first-child {
		margin-top: 0;
		padding-top: 0;
		border-top: none;
	}
}


.blogCommentSend {
	margin: 40px 0 0;
}

.blogCommentSend__hl {
	@include fs(18);
	color: $md-grey-900;
	
	@include tablet2 {
		margin: 10px 0;
	} //tablet2
	
	@include sp {
		margin: 10px 0;
		@include fs(16);
	} //sp
}


#BlogCommentAddForm {
	padding: 0 !important;
	
	@include tablet2 {
		padding: 10px 0 !important;
	} //table2
	
	@include sp {
		padding: 10px 0 !important;
	} //sp
	
	input {
		@include tablet2 {
			width: 100%;
		} //tablet2
		
		@include sp {
			width: 100%;
		} //sp
	}
}


.authCaptcha {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}


.authCaptcha__image {
	margin:0;
	vertical-align:middle;
	
	@include tablet {
		margin:0 20px 10px 0;
	} //tablet
	
	@include sp {
		margin:0 0 10px;
	} //sp
}

.authCaptcha__message {
	margin: 0 10px;
}

#blogCommentMessage {
	width: 100%;
}

.resultMessage {
	padding:20px 0;
	@include fs(18);
	border-radius: 3px;
	background:$md-black;
	color:$md-white;
	
	@include sp {
		padding:15px 0;
		@include fs(16);
	} //sp
}


