@charset "utf-8";

// ============================================================
// btn style
// ============================================================

.btn {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	box-sizing: border-box;
	background: $md-grey-100;
	border: 1px solid $md-grey-300;
	color: $textColor;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
	transition: all 0.5s ease;
	
	&__reset {
		width: 150px;
		@include fs(18);
		
		&:hover {
			opacity: .6;
		}
	}
	
	&__submit {
		width: 300px;
		background: $md-light-green-800;
		border: 1px solid $md-light-green-800;
		color: $md-white;
		@include fs(18);
		font-weight: 500;

		&:hover {
			opacity: .6;
		}
	}
	
	&__comment {
		width: 300px;

		&:hover {
			opacity: .6;
		}
	}
}


.btnContainer {
	display: flex;
	justify-content: center;
}


