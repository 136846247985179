@charset "utf-8";
// ============================================================
// カラー設定
// ============================================================

/*----------------------------------------
	color
----------------------------------------*/
$cBase1:#000;
$cBase2:#698C00;
$cBase3:#FFC926;
$cBase4:#DEF4C8;
$cBase5:#F5FCEE;

//SNS Color
$color-Facebook: #3251A5;
$color-twitter: #178EF2;
$color-line: #1BAE0D;
