@charset "utf-8";
// ============================================================
// 設定
// ============================================================

// HTML5の場合は「true」
$html5 : true;

// compass ブラウザサポート
$legacy-support-for-ie6 : true;
$legacy-support-for-ie7 : true;

$experimental-support-for-webkit : true;
$experimental-support-for-mozilla : true;
$experimental-support-for-opera : true;
$experimental-support-for-microsoft : true;
$experimental-support-for-khtml : false;

//ie9用 svg
$experimental-support-for-svg: true;

// 画像パス
$imgPath : "../img/";

// commonフォルダ内の画像のパス
$commonImgPath: "../common/images/";

// ページの全体の横幅
$baseWidth : 1100px;

// 標準font-family
$fontFamily1 : YuGothic, "游ゴシック","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", verdana, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;
$fontFamily2 : Yu Mincho, "游明朝体","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","ＭＳ Ｐ明朝","MS PMincho", Sans-Serif;

// 標準行間
$lineHeight : 160%;

// 標準テキストカラー
$textColor : $md-grey-900;

// 標準リンクカラー
$linkColor : $md-light-green-800;
$linkColor_visit : $md-grey-900;
$linkColor_active : $md-grey-900;
$linkColor_hover : $md-grey-900;