@charset "utf-8";

// ----------------------------------------------------------------------
// img のレスポンシブ対応
// ----------------------------------------------------------------------

@mixin imgmax {
	img {
		max-width:100%;
		height:auto;
	}
}

