@charset "utf-8";

// ============================================================
// form style
// ============================================================

input, select {
	padding:2px 4px;
	height:32px;
	box-sizing: border-box;
	border-radius: 3px;
	background: $md-white;
	border:1px solid $md-grey-300;
	vertical-align: top;
	
	@include input-placeholder {
		color: $md-grey-400;
		@include fs(12);
	}
}

input:not([type='checkbox']):not([type='radio']), textarea {
	@include tablet1 {
		-webkit-appearance:none;
	} //tablet1
	
	@include tablet2 {
		-webkit-appearance:none;
	} //tablet2

	@include sp {
		-webkit-appearance:none;
	} //sp
}

input:not([type='submit']) {
	&:focus {
		outline: 0;
		background: rgba($md-green-50, .3);
	}
}

input[type='search'] {
	-webkit-appearance: none;
}

input[type='time'] {
	font-family: $fontNotoSans;
}

label {
	cursor: pointer;
}

textarea {
	padding:4px 6px;
	width: 100%;
	box-sizing: border-box;
	border-radius: 3px;
	background: $md-white;
	border:1px solid $md-grey-300;
	
	@include input-placeholder {
		color: $md-grey-400;
	}

	&:focus {
		outline: 0;
		background: rgba($md-green-50, .3);
	}
}

#FieldMessageName1, #FieldMessageNameKana1 {
	margin-right: 20px;
	display: inline-flex;
	align-items: center;

	.mail-before-attachment {
		margin-right: 5px;
	}
}

#FieldMessageName2, #FieldMessageNameKana2 {
	display: inline-flex;
	align-items: center;

	.mail-before-attachment {
		margin-right: 5px;
	}
}

#FieldMessageSex {
	display: flex;
	align-items: center;
	
	label {
		margin-right: 20px;
	}
}

#MailMessageEmail1 {
	margin: 5px 0;
}

#FieldMessageTel1, #FieldMessageTel2, #FieldMessageTel3 {
	display: inline-flex;
	align-items: center;
	
	.mail-after-attachment {
		margin: 0 5px;
	}
}

#MailMessageTel1, #MailMessageTel2, #MailMessageTel3 {
	width: 80px;
}

#FieldMessageZip {
	display: flex;
	align-items: center;
	
	.mail-before-attachment {
		margin-right: 5px;
	}
	
	.mail-attention {
		margin-left: 5px;
	}
}

#FieldMessageAddress1, #FieldMessageAddress2 {
	margin: 5px 0;
	display: block;
}

#FieldMessageCategory {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#MailMessageMessage {
	width: 100%;
}

#FieldMessageRoot {
	margin-bottom: 5px;
	display: block;
}

.form-s {
	width: 200px;

	@include tablet1 {
		width: 100%;
	} //tablet1

	@include tablet2 {
		width: 100%;
	} //tablet2

	@include sp {
		width: 100%;
	} //sp
}

.form-l {
	width: 500px;
	
	@include tablet1 {
		width: 100%;
	} //tablet1
	
	@include tablet2 {
		width: 100%;
	} //tablet2
	
	@include sp {
		width: 100%;
	} //sp
}

.error-message {
	margin: 5px 0 0;
	color: $md-red-900;
	@include fs(14);
	text-align: center;
}

.authCaptcha {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	
	@include tablet2 {
		flex-direction: column;
	} //tablet2
	
	@include sp {
		flex-direction: column;
	} //sp
	
	&__message {
		margin-left: 10px;
	}
	
	.error-message {
		width: 100%;
	}
}


#MailMessageAuthCaptcha {
	width:200px;

	@include tablet2 {
		width:96%;
	} //tablet2

	@include sp {
		width:94%;
	} //sp
} //MessageAuthCaptcha


.submit {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	
	input {
		margin: 0 10px;
	}
} //submit
