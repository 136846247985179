@charset "utf-8";

// ----------------------------------------------------------------------
// font-size rem
// ----------------------------------------------------------------------

@mixin fs($size: 24, $base: 16, $important: false) {
	@if $important == 'important' or $important == true {
		$important: ' !important';
	} @else {
		$important: '';
	}
	
	font-size: $size + px#{$important};
	font-size: ($size / $base) * 1rem#{$important};
}


