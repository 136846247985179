@charset "utf-8";

/*----------------------------------------
	news
----------------------------------------*/
.entryContainer {
	display: flex;
	flex-direction: row-reverse;

	@include tablet1 {
		flex-direction: column;
	} //tablet1

	@include tablet2 {
		flex-direction: column;
	} //tablet2

	@include sp {
		flex-direction: column;
	} //sp
}

.entryArea {
	margin-left: auto;
	padding-bottom: 80px;
	width: 71%;

	@include tablet1 {
		padding-bottom: 40px;
		width: 100%;
	} //tablet1

	@include tablet2 {
		padding-bottom: 40px;
		width: 100%;
	}

	@include sp {
		padding-bottom: 20px;
		width: 100%;
	} //sp
}


//記事一覧
.entryList {
	margin-bottom: 80px;
	display: flex;

	@include sp {
		margin: 40px 0 60px;
		flex-direction: column;
	} //sp
}

.entryList__eyeCatch {
	margin: 0;
	width: 33.419%;

	@include sp {
		margin: 0 auto;
		width: calc(100% - 40px);
		float: none;
	} //sp

	a {
		&:hover {
			opacity: .6;
		}
	} //a
}

.entryList__outline {
	margin-left: auto;
	width: 62.724%;
	position: relative;

	@include sp {
		margin-top: 15px;
		width: 100%;
		float: none;
	} //sp
}

.entryList__meta {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.entryList__hl {
	margin: 16px 0;
	padding: 0 0 16px;
	width: 100%;
	line-height: 120%;
	border-bottom: 1px solid #DDD;
	font-family: $fontNotoSerif;
	@include fs(20);
	text-align: left;
	order: 3;

	@include sp {
		@include fs(18);
	} //sp

	a {
		color: $md-light-green-800;
		text-decoration: none;

		&:hover {
			color: $md-black;
		}
	}
}

.entryList__date {
	color: $md-grey-600;
	order: 1;
}

.entryList__category {
	margin: 0 0 0 auto;
	order: 2;

	a {
		padding: 6px 10px;
		line-height: 100%;
		display: inline-block;
		border-radius: 3px;
		background: $md-light-green-800;
		@include fs(13);
		color: $md-white !important;
		text-align: center;
		text-decoration: none;

		@include sp {
			padding: 6px 8px;
			@include fs(11);
		} //sp

		&:hover {
			background: $md-black;
		}
	} //a
}

.entryList__body {
	display: flex;
	flex-direction: column;
}

.entryList__excerpt {
	margin: 0;
}

.entryList__more {
	margin-left: auto;

	@include sp {
		margin-top: 10px;
	} //sp

	i {
		margin-right: 5px;
		display: inline-block;
		color: $md-light-green-800;
		@include fs(12);
		position: relative;
		top: 1px;
	} //i

	a {
		display: flex;
		align-items: center;
		text-decoration: none;

		&:hover {
			i {
				color: $md-black;
			}
		}
	}
}



//記事詳細
.entryArea__detail {
	padding-bottom: 0;
}

.entry {
	padding-bottom: 80px;

	@include sp {
		padding-bottom: 40px;
	} //sp

	&:last-child {
		padding-bottom: 20px;
	}
}


//entry header
.entry__header {
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;

	@include sp {
		margin-bottom: 0;
	} //sp
}

.entry__hl {
	margin: 10px 0 25px;
	padding: 0 10px 25px;
	width: 100%;
	background: none;
	border: none;
	border-bottom: 1px solid $md-grey-300;
	box-shadow: none;
	@include fs(28);
	font-weight: 400;
	line-height: 130%;
	text-align: left;

	@include sp {
		@include fs(20);
	} //sp
}

.entry__date {
	padding: 0 10px;
	color: $md-grey-600;
}

.entrySns {
	margin: 0 10px 0 auto;
	padding: 0;
	display: flex;

	@include tablet {
		margin: 15px 0 0 0;
		width: 100%;
	} //tablet

	@include sp {
		margin: 15px 0 0 0;
		width: 100%;
	} //sp
}

.entrySns__item {
	margin-left: 10px;
	list-style: none;

	@include tablet {
		margin: 0 10px 0 0;
	} //tablet

	@include sp {
		margin: 0 10px 0 0;
	} //sp
}

.fb-like {
	&.fb_iframe_widget {
		@include display-flex;
	}
}


//entry body
.entryBody, .eyeCatch, .post-body {
	margin: 40px 0 !important;
	padding: 0 10px;
	clear: both;
}

.post-detail, #post-detail {
	margin: 40px 0 !important;
	padding: 0 10px;
}

.entry__meta {
	padding-top: 20px;
	width: 100%;
	border-top: 1px solid $md-grey-300;
}

.entryMetaList {
	margin: 0 10px;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	
	@include tablet2 {
		flex-direction: column;
	} //tablet2
	
	@include sp {
		flex-direction: column;
	} //sp
}

.entryMetaList__item {
	margin-right: 20px;
	@include fs(14);
	list-style: none;
}


.entryArchive {
}

.entryArchive__hl {
	margin: 0 0 40px;
	padding: 10px 15px;
	background: $md-black;
	border-radius: 4px;
	font-family: $fontNotoSerif;
	@include fs(18);
	font-weight: 400;
	color: $md-white;

	@include sp {
		@include fs(16);
	} //sp
}


.tag {
	padding: 0 !important;
	@include fs(14,16,important);
}


