@charset "utf-8";

// ============================================================
// headline style
// ============================================================

.headline {
	margin: 0 0 40px;
	line-height: 120%;
	@include fs(30);
	position: relative;
	text-align: center;
	font-weight: normal;

	@include tablet2 {
		margin: 0 0 30px;
		@include fs(24);
	} //tablet2

	@include sp {
		margin: 0 0 30px;
		@include fs(18);
	} //sp

	& * {
		line-height: 120%;
	}

	//レイアウト
	&--left {
		text-align: left;
	}

	&--center {
		text-align: center;
	}

	&--right {
		text-align: right;
	}
	
	//下部ボーダー付き
	&__underline {
		padding-bottom: 25px;

		@include tablet2 {
			padding-bottom: 15px;
			@include fs(24);
		} //tablet2

		@include sp {
			padding-bottom: 15px;
			@include fs(18);
		} //sp
		
		&:before {
			margin-left: -30px;
			content: '';
			width: 60px;
			height: 1px;
			background: $md-light-green-800;
			position: absolute;
			bottom: 0;
			left: 50%;

			@include sp {
				margin-left: -15px;
				width: 30px;
			} //sp
		}

		&--left {
			padding-bottom: 25px;
			text-align: left;
			font-weight: normal;

			@include tablet2 {
				margin: 0 0 30px;
				padding-bottom: 15px;
				@include fs(24);
			} //tablet2

			@include sp {
				margin: 0 0 30px;
				padding-bottom: 15px;
				@include fs(20);
			} //sp

			&:before {
				margin-left: 0;
				content: '';
				width: 60px;
				height: 1px;
				background: $md-light-green-800;
				position: absolute;
				bottom: 0;
				left: 0;

				@include sp {
					width: 30px;
				} //sp
			}
		}

		&--right {
			padding-bottom: 25px;
			text-align: right;
			font-weight: normal;

			@include tablet2 {
				margin: 0 0 30px;
				padding-bottom: 15px;
				@include fs(24);
			} //tablet2

			@include sp {
				margin: 0 0 30px;
				padding-bottom: 15px;
				@include fs(20);
			} //sp

			&:before {
				margin-left: 0;
				content: '';
				width: 60px;
				height: 1px;
				background: $md-light-green-800;
				position: absolute;
				bottom: 0;
				left: inherit;
				right: 0;

				@include sp {
					width: 30px;
				} //sp
			}
		}
	}
	
	//枠線
	&__border {
		padding: .4em .6em;
		line-height: 120%;
		border-radius: 3px;
		border: 1px solid $md-grey-900;
		box-sizing: border-box;
		position: relative;
		text-align: center;
	}
	
	//両サイド横線
	&__sideBorder {
		display: flex;
		justify-content: center;
		align-items: center;
		
		&--text {
			margin: 0 20px;
			display: block;
			
			@include tablet2 {
				margin: 0 15px;
			} //tablet2
			
			@include sp {
				margin: 0 10px;
			} //sp
		}
		
		&--line {
			width: 40px;
			height: 1px;
			display: block;
			background-color: $md-grey-900;
			
			@include tablet2 {
				width: 30px;
			} //tablet2
			
			@include sp {
				width: 20px;
			} //sp
		}
	}
	
	//フォントサイズ
	&__fs {
		&--xs {
			@include fs(18);
			
			@include tablet2 {
				@include fs(17);
			} //tablet2
			
			@include sp {
				@include fs(16);
			} //sp
		}

		&--s {
			@include fs(24);

			@include tablet2 {
				@include fs(22);
			} //tablet2

			@include sp {
				@include fs(20);
			} //sp
		}

		&--m {
			@include fs(30);

			@include tablet2 {
				@include fs(26);
			} //tablet2

			@include sp {
				@include fs(22);
			} //sp
		}

		&--l {
			@include fs(36);

			@include tablet2 {
				@include fs(31);
			} //tablet2

			@include sp {
				@include fs(26);
			} //sp
		}

		&--xl {
			@include fs(42);

			@include tablet2 {
				@include fs(36);
			} //tablet2

			@include sp {
				@include fs(30);
			} //sp
		}
	}
	
	//サブ見出し
	&__sub {
		margin-top: -.05em;
		display: block;
		width: 100%;
		@include transform(scale(.6));
	}
}







