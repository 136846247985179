@charset "utf-8";

// ============================================================
// checkbox style
// ============================================================
// （例）
// <label>
// 	<input name="checkboxname" class="checkboxInput" value="1" type="checkbox">
// 	<span class="checkboxInput-style">ラベル名</span>
// </label>

.checkboxInput {
	display: none;

	&:checked + .checkboxInput-style{
		color: $md-cyan-600;
	}

	&:checked + .checkboxInput-style {
		&:after{
			content: "";
			width: 6px;
			height: 12px;
			display: block;
			border-bottom: 4px solid $md-cyan-600;
			border-right: 4px solid $md-cyan-600;
			transform: rotate(40deg);
			position: absolute;
			top: -3px;
			left: 5px;
			cursor: pointer;
		}
	}
}

.checkboxInput-style {
	padding-left: 22px;
	position: relative;
	white-space: nowrap;
	top: -1px;

	&:before {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background: $md-blue-grey-50;
		box-shadow: 0 0 0 2px rgba($md-white, 1) inset;
		border: 1px solid $md-blue-grey-200;
		border-radius: 2px;
		box-sizing: border-box;
		position: absolute;
		top: 2px;
		left: 0;
		cursor: pointer;
	}

	&-noText {
		padding-left: 15px;
	}
}
