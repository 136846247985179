@charset "utf-8";

// ----------------------------------------------------------------------
// clearfix
// ----------------------------------------------------------------------

// @extend %clear; を記述でフロート解除＆プレースホルダーセレクタでまとめ

/*----------------------------------------
	clearfix
----------------------------------------*/
@mixin clear {
	&:after {
		content:"";
		clear:both;
		height:0;
		display:block;
		visibility:hidden;
	}
}

%clear{
	@include clear;
}


@mixin clearfixBase {
	.clearfix {
		@if $legacy-support-for-ie7 {
		min-height: 1px;
	}
	
	@if $legacy-support-for-ie6 {
		_height: 1%;
	}
	
		&:after {
			content:"";
			clear:both;
			height:0;
			display:block;
			visibility:hidden;
		}
	}
}

